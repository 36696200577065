<template>
  <div class="list">
    <div class="df-row">
      <div class="df-col">
        <el-select
          v-model="submitData.category_id"
          size="mini"
          filterable
          @change=""
        >
          <el-option
            v-for="item in categorySelector"
            :key="item.CategoryId"
            :label="`${item.Prefix + item.Name}`"
            :value="item.CategoryId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-select
          v-model="submitData.onsale"
          size="mini"
          filterable
          @change=""
        >
          <el-option
            v-for="item in onSaleSelector"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-input
          v-model="submitData.word"
          placeholder="请输入商品名称"
          size="mini"
          clearable
        ></el-input>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddGoods('')"
          >新增商品</el-button
        >
      </div>
    </div>

    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      size="mini"
      border
      stripe
    >
      <el-table-column prop="ItemId" label="商品ID" width="100">
      </el-table-column>
      <el-table-column prop="" label="商品图片" width="100">
        <template slot-scope="scope">
          <el-image
            style="width: 60px; height: 60px;"
            :src="scope.row.CoverImage"
            :preview-src-list="[scope.row.CoverImage]"
            fit="fill"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="ItemName" label="商品名称"> </el-table-column>
      <el-table-column prop="CategoryName" label="商品分类" width="120">
      </el-table-column>
      <el-table-column prop="ViewCount" label="浏览数" width="120"> </el-table-column>
      <el-table-column prop="Sort" label="商品排序" width="120">
      </el-table-column>
      <el-table-column prop="" label="商品状态" width="120">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.OnSale ? 'success' : 'warning'"
            size="default"
            >{{ scope.row.OnSale ? "上架" : "下架" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="添加时间">
        <template slot-scope="scope">{{
          !scope.row.CreateTime
            ? ""
            : util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(scope.row.CreateTime * 1000)
              )
        }}</template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onAddGoods(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="mini"
            :disabled="delDisabled"
            @click="deleteItem(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <!-- 新增商品对话框 -->
    <AddGoodsDialog
      ref="AddGoodsDialog"
      @success="getItemMaintList"
    ></AddGoodsDialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

import AddGoodsDialog from "./components/add-goods-dialog.vue";

export default {
  components: { AddGoodsDialog },

  data() {
    return {
      totalRecord: 0,
      tableMaxHeight: "",
      submitData: {
        category_id: -1,
        onsale: -1,
        is_groupon: -1,
        word: "",
        page_index: 1,
        page_size: 20,
      },
      categorySelector: [], // 分类列表
      onSaleSelector: [], // 商品状态
      tableData: [],
      loading: false,
      delDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initItemMaintList();
    await this.getItemMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 初始化商品列表
    async initItemMaintList() {
      try {
        let { data } = await Shop.initItemMaintList();
        data.categorySelector.unshift({
          CategoryId: -1,
          Prefix: "",
          Name: "全部",
          ParentId: 0,
          Image: "",
          Sort: 0,
          Level: 1,
          Leaf: 1,
        });
        this.categorySelector = data.categorySelector;
        this.onSaleSelector = data.onSaleSelector;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询商品列表
    async getItemMaintList() {
      this.loading = true;
      try {
        let { data } = await Shop.getItemMaintList(this.submitData);
        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getItemMaintList();
    },

    // 打开添加、编辑商品对话框
    onAddGoods(event) {
      this.$refs.AddGoodsDialog.onShowDialog(event);
    },

    // 删除商品
    deleteItem(event) {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.delDisabled = true;
        try {
          let { errcode } = await Shop.deleteItem({
            item_id: event.ItemId,
          });
          if (errcode == 0) {
            this.getItemMaintList();
            this.$message.success("删除成功");
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.delDisabled = false;
        }
      });
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getItemMaintList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getItemMaintList();
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  padding-top: 20px;
  .df-row {
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>
